<template>
  <transition name="fade">
    <div :class="showMenu ? 'menuopen' : ''">
      <header class="header" id="header">
        <div class="inner">
          <router-link class="logo" to="/">
            <img alt="Chameleon Creative Marketing" src="../assets/chameleon.png" />
          </router-link>
          <a :class="showMenu ? 'open' : ''" @click.prevent="showMenu = !showMenu" class="menu-link" href>
            <font-awesome-icon icon="bars" v-if="!showMenu" />
            <font-awesome-icon icon="times" v-else />
            <span>Menu</span>
          </a>
          <template v-if="settings">
            <template v-if="loc == 'd' || $route.params.location == 'barnsley'">
              <a
                :href="'tel:' + settings.doncaster_telephone.replace(/ /g, '')"
                class="phone-link"
                v-if="settings.doncaster_telephone"
              >
                <font-awesome-icon icon="phone" />
                {{ settings.doncaster_telephone }}
              </a>
            </template>
            <template v-else>
              <a
                :href="'tel:' + settings.retford_telephone.replace(/ /g, '')"
                class="phone-link"
                v-if="settings.retford_telephone"
              >
                <font-awesome-icon icon="phone" />
                {{ settings.retford_telephone }}
              </a>
            </template>
          </template>
        </div>
      </header>
      <div :class="showMenu ? 'show' : ''" class="menu">
        <div class="inner">
          <div class="socials">
            <template v-if="settings && settings.doncaster_telephone && settings.retford_telephone">
              <a
                :href="'tel:' + settings.doncaster_telephone.replace(/ /g, '')"
                aria-label="Call us"
                class="phone"
                v-if="loc == 'd'"
              >
                <font-awesome-icon icon="phone" />
              </a>
              <a 
                :href="'tel:' + settings.retford_telephone.replace(/ /g, '')"
                aria-label="Call us"
                class="phone"
                v-else-if="settings.retford_telephone"
              >
                <font-awesome-icon icon="phone" />
              </a>
            </template>
            <a aria-label="Email us" class="email" href="mailto:hello@chameleoncreative.marketing">
              <font-awesome-icon icon="envelope" />
            </a>
            <a
              :href="settings.linked_in"
              aria-label="LinkedIn"
              class="linkedin"
              rel="noreferrer"
              target="_blank"
              v-if="settings && settings.linked_in"
            >
              <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin-in' }" />
            </a>
            <a
              :href="settings.instagram"
              aria-label="Instagram"
              class="instagram"
              rel="noreferrer"
              target="_blank"
              v-if="settings && settings.instagram"
            >
              <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }" />
            </a>
          </div>
          <nav>
            <ul>
              <li>
                <span>
                  <router-link to="/">Home</router-link>
                </span>
              </li>
              <li>
                <span>
                  <router-link to="/about">About Us</router-link>
                </span>
              </li>
              <li>
                <span>
                  <router-link to="/what-we-do">What We Do</router-link>
                </span>
              </li>
              <li>
                <span>
                  <router-link to="/work">Our Work</router-link>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div :class="!showMenu ? '' : 'obscure'" class="wrapper">
        <intro-elem v-if="!$route.name.includes('project') && !isPrivacyPage && !isTermsPage" />
        <template v-if="$route.name == 'index'">
          <hero-alt-elem />
          <secondary-elem />
          <home-lower-elem />
        </template>

        <template v-if="$route.name == 'web-design'">
          <hero-alt-elem />
          <secondary-elem />
        </template>

        <template v-if="$route.name == 'web-design-landing'">
          <hero-alt-elem />
          <secondary-elem />
          <clients-elem />
          <home-lower-elem />
        </template>

        <work-elem v-if="isWorkPage || $route.name == 'design-agency'" />

        <project-elem v-if="$route.name.includes('project')" />

        <what-elem v-if="isWhatWeDoPage" />

        <about-elem v-if="isAboutPage" />

        <small-business-elem v-if="$route.name == 'small-business'" />

        <generic-elem v-if="isPrivacyPage || isTermsPage" />

        <footer class="footer">
          <div v-if="isHomePage" class="team-image">
            <div class="inner"><img src="/team-home-footer.png" alt="The Chameleon team" /></div>
          </div>
          <div :style="isHomePage ? 'margin-top:0' : ''" class="footer-upper">
            <div class="inner">
              <div class="socials">
                <a
                  :href="settings.linked_in"
                  aria-label="LinkedIn"
                  class="linkedin"
                  rel="noreferrer"
                  target="_blank"
                  v-if="settings && settings.linked_in"
                >
                  <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin-in' }" />
                </a>
                <a
                  :href="settings.instagram"
                  aria-label="Instagram"
                  class="instagram"
                  rel="noreferrer"
                  target="_blank"
                  v-if="settings && settings.instagram"
                >
                  <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }" />
                </a>
              </div>
              <div class="footer-contacts">
                <div class="footer-header">Fancy getting to know Chameleon?</div>
                <div class="footer-sub">Give us a buzz</div>
                <template v-if="settings">
                  <div v-if="loc !== 'd' || loc == 'r'">
                    <a v-if="settings.retford_telephone" :href="'tel:' + settings.retford_telephone.replace(/ /g, '')">{{
                      settings.retford_telephone
                    }}</a>
                  </div>
                  <div v-if="loc !== 'r' || loc == 'd'">
                    <a v-if="settings.doncaster_telephone" :href="'tel:' + settings.doncaster_telephone.replace(/ /g, '')">{{
                      settings.doncaster_telephone
                    }}</a>
                  </div>
                </template>
                <div class="footer-sub">Ping us an email</div>
                <div v-if="settings && settings.email">
                  <a :href="'mailto:' + settings.email">{{ settings.email }}</a>
                </div>
                <div class="footer-sub">Drop in</div>
                <div class="footer-addresses">
                  <template v-if="settings">
                    <a
                      href="https://goo.gl/maps/C3UNzPAxEEGD97NZA"
                      rel="noreferrer"
                      target="_blank"
                      v-html="settings.retford_address.replace(/[\n\r]/g, '<br>')"
                      v-if="(loc !== 'd' || loc == 'r') && settings.retford_address"
                    ></a>
                    <a
                      href="https://goo.gl/maps/ETavCX6vC1wpyXnm8"
                      rel="noreferrer"
                      target="_blank"
                      v-html="settings.doncaster_address.replace(/[\n\r]/g, '<br>')"
                      v-if="(loc !== 'r' || loc == 'd') && settings.doncaster_address"
                    ></a>
                  </template>
                </div>
              </div>
              <img
                alt="25 big ones"
                class="footer-celebrate"
                :src="settings.years_image"
                v-if="$route.name !== 'index'"
              />
              <img alt="Chameleon Creative" class="circle" src="../assets/circle.png" />
            </div>
          </div>
          <div class="footer-lower">
            <div class="inner">
              <div class="footer-links">
                <router-link to="/privacy">Privacy Policy</router-link>
                <router-link to="/terms">Terms &amp; Conditions</router-link>
              </div>
              <div class="footer-legal" v-if="settings">
                <div>{{ settings.legal_line_1 }}</div>
                <div>{{ settings.legal_line_2 }}</div>
              </div>
            </div>
          </div>
          <div class="footer-info" v-if="$route.meta.footer || page.seo_paragraph">
            <div class="inner">
              <div v-html="$route.meta.footer || page.seo_paragraph"></div>
            </div>
          </div>
        </footer>
      </div>
      <div class="mobile-actions">
        <a aria-label="Call us" v-if="settings && settings.retford_telephone" :href="'tel:' + settings.retford_telephone.replace(/ /g, '')">
          <span>
            <font-awesome-icon icon="phone" />
          </span>
        </a>
        <a aria-label="Email us" href="mailto:hello@chameleoncreative.marketing">
          <span>
            <font-awesome-icon icon="envelope" />
          </span>
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapGetters } from "vuex"

import api from "@/services/api"

import IntroElem from "@/components/Intro.vue"
import HeroAltElem from "@/components/HeroAlt.vue"
import SecondaryElem from "@/components/Secondary.vue"
import HomeLowerElem from "@/components/HomeLower.vue"
import ProjectElem from "@/components/Project.vue"
import WorkElem from "@/components/Work.vue"
import WhatElem from "@/components/What.vue"
import AboutElem from "@/components/About.vue"
import GenericElem from "@/components/Generic.vue"
import SmallBusinessElem from "@/components/SmallBusiness.vue"
import ClientsElem from "@/components/Clients.vue"

export default {
  name: "page",
  components: {
    IntroElem,
    HeroAltElem,
    SecondaryElem,
    HomeLowerElem,
    ProjectElem,
    WorkElem,
    WhatElem,
    AboutElem,
    GenericElem,
    SmallBusinessElem,
    ClientsElem
  },
  data() {
    return {
      showMenu: false,
      retford_number: ["retford", "worksop", "lincoln", "mansfield", "nottingham", "derby"]
    }
  },
  computed: {
    ...mapState(["page", "settings", "loc"]),
    ...mapGetters([
      "isHomePage",
      "isWorkPage",
      "isSmallBusinessPage",
      "isWhatWeDoPage",
      "isAboutPage",
      "isPrivacyPage",
      "isTermsPage"
    ])
  },
  metaInfo() {
    return {
      title: this.$route.meta.title || this.page.title,
      meta: [{ name: "description", content: this.$route.meta.description || this.page.meta_desc }]
    }
  },
  created: function() {
    var route = this.$route
    var url = route.params.url
    var promises = []
    var store = this.$store

    if (!url) store.commit("setPage", {})

    if (route.name == "404") this.page = { main_text: "Sorry, this page cannot be found!" }

    if (route.name == "page-by-url") promises.push(api.getPageByURL(url))
    if (route.name == "index") promises.push(api.getHomePage())

    if (this.$route.query.l) store.commit("setLoc", this.$route.query.l)

    Promise.all(promises).then(vals => {
      if (vals.length) {
        var returnedpage = vals[0]
        store.commit("setPage", returnedpage)
      }

      window.onscroll = function() {
        var header = document.getElementById("header")

        if (window.pageYOffset > header.offsetHeight) {
          header.classList.add("scrolled")
        } else {
          header.classList.remove("scrolled")
        }
      }
    })

    store.dispatch("getSettings")
    store.dispatch("getHomePage")
    store.dispatch("getAboutPage")
    store.dispatch("getWorkPage")
  }
}
</script>
