var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content",attrs:{"id":"content"}},[_vm._m(0),_c('div',{staticClass:"inner"},[_vm._m(1),_c('img',{directives:[{name:"in-viewport",rawName:"v-in-viewport",value:({ margin: '-20% 0px' }),expression:"{ margin: '-20% 0px' }"}],staticClass:"split-image",attrs:{"src":"/profiles/jord-colour.jpg","alt":"Contact Jordan"}})]),_c('div',{staticClass:"contrast"},[_c('div',{staticClass:"inner"},[_vm._m(2),_c('div',{directives:[{name:"in-viewport",rawName:"v-in-viewport.once",value:({ margin: '-20% 0px' }),expression:"{ margin: '-20% 0px' }",modifiers:{"once":true}}],staticClass:"expand-logos"},[_c('img',{staticClass:"expand-logos-1",attrs:{"src":"/jord/PG-logo-final-copy.png","alt":"Logo design"}}),_c('img',{staticClass:"expand-logos-2",attrs:{"src":"/jord/Oak_logo.png","alt":"Logo designer"}}),_c('img',{staticClass:"expand-logos-3",attrs:{"src":"/jord/Allsports-logo.png","alt":"Graphic design"}}),_c('img',{staticClass:"expand-logos-3",attrs:{"src":"/jord/debonair.jpg","alt":"Company logo design"}})])])]),_c('div',{staticClass:"padded"},[_c('div',{staticClass:"inner"},[_vm._m(3),_c('div',{directives:[{name:"in-viewport",rawName:"v-in-viewport",value:({ margin: '-20% 0px' }),expression:"{ margin: '-20% 0px' }"}],staticClass:"expand-web"},[_c('img',{staticClass:"expand-web-1",attrs:{"src":"/jord/PG.png","alt":"Responsive web page"}}),_c('img',{staticClass:"expand-web-2",attrs:{"src":"/jord/oak1.png","alt":"Website development"}}),_c('img',{staticClass:"expand-web-3",attrs:{"src":"/jord/tjprince-mobile.png","alt":"Mobile web design"}})])])]),_c('div',{staticClass:"contrast"},[_c('div',{staticClass:"inner"},[_vm._m(4),_c('div',{directives:[{name:"in-viewport",rawName:"v-in-viewport.once",value:({ margin: '-20% 0px' }),expression:"{ margin: '-20% 0px' }",modifiers:{"once":true}}],staticClass:"expand-pack"},[_vm._m(5),_vm._m(6),_vm._m(7)])])]),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contrast"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"heading"},[_vm._v("what we do best")]),_c('ul',{staticClass:"list"},[_c('li',[_vm._v("Branding & logo design")]),_c('li',[_vm._v("Web design")]),_c('li',[_vm._v("Graphic design")]),_c('li',[_vm._v("Printing")]),_c('li',[_vm._v("Digital marketing")]),_c('li',[_vm._v("E-campaigns")]),_c('li',[_vm._v("Social media")]),_c('li',[_vm._v("Vehicle graphics")]),_c('li',[_vm._v("Signs and displays")])])]),_c('div',{staticClass:"expand-best"},[_c('div',{staticClass:"expand-best-1"},[_c('img',{attrs:{"src":require("../assets/celebrating.png?v=2"),"alt":"25 years"}})]),_c('div',{staticClass:"expand-best-2"},[_c('img',{attrs:{"src":"/jord/pga-iphone.png","alt":"Responsive web design"}})]),_c('div',{staticClass:"expand-best-3"},[_c('img',{attrs:{"src":"/jord/worksop-web-design-5.jpg","alt":"Business card design"}}),_c('img',{attrs:{"src":"/jord/retford-web-designers-4.jpg","alt":"Vehicle signage"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"split-text"},[_c('div',{staticClass:"heading"},[_vm._v("Contact Chameleon Creative")]),_c('p',[_vm._v(" Hi there, I’m Jordan and I’m your ﬁrst point of contact if you’re looking to get your business off the ground or you want to improve your current business marketing perception. ")]),_c('p',[_vm._v(" Being part of Chameleon Creative is great for me as I get the opportunity to work alongside a fantastic creative team but I am able to focus on my own clients in and around Retford, Worksop and North Nottinghamshire. ")]),_c('p',[_vm._v("I specialise in offering small local businesses the best marketing at the most competitive prices.")]),_c('p',[_vm._v("Why not give me a call or drop me an email and we can chat about how we can help.")]),_c('div',{staticClass:"sub-heading"},[_vm._v(" Jordan Reep "),_c('br'),_c('a',{attrs:{"href":"tel:01777553443"}},[_vm._v("01777 553443")]),_vm._v(" / "),_c('a',{attrs:{"href":"tel:07501977709"}},[_vm._v("07501 977709")]),_c('br'),_c('a',{attrs:{"href":"mailto:jordan@chameleon-creative.co.uk"}},[_vm._v("jordan@chameleon-creative.co.uk")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-right"},[_c('div',{staticClass:"heading"},[_vm._v("Logo design from £250")]),_c('p',[_vm._v(" Every business needs a logo, a brand, an identity in order to get noticed and remembered. We'll develop your perfect brand and help you promote it so that you stand out against the competition. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[_c('div',{staticClass:"heading"},[_vm._v("Website design from £599")]),_c('p',[_vm._v(" We make sure that your website, regardless of its size or complexity draws your clients in, encourages them to find out more and have an enjoyable experience. ")]),_c('p',[_vm._v(" Every website we develop is fully responsive so it adapts to any device. We also provide web hosting and domain name registration. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-right"},[_c('div',{staticClass:"heading"},[_vm._v(" Special offer: "),_c('br'),_vm._v("marketing pack £1,599 ")]),_c('p',[_vm._v(" This is an amazing deal and will get you marketing your business professionally and affordably, the pack consist of: ")]),_c('p',[_c('strong',[_vm._v(" Logo design, 250 full colour business cards, 5000 full colour ﬂyers, vehicle graphics (small/medium van), 1 roller banner and a responsive web page including web hosting for 1 year. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expand-pack-1"},[_c('div',{staticClass:"expand-wrap"},[_c('img',{attrs:{"src":"/jord/Oak-electrical-concepts.jpg","alt":"Logo concepts"}}),_c('img',{attrs:{"src":"/jord/tjp-b.jpg","alt":"Business cards"}}),_c('img',{attrs:{"src":"/jord/allsports.jpg","alt":"Vehicle signage"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expand-pack-2"},[_c('div',{staticClass:"expand-wrap"},[_c('img',{attrs:{"src":"/jord/pgalloys.jpg","alt":"Brochure design"}}),_c('img',{attrs:{"src":"/jord/ipad_oak.jpg","alt":"Responsive website"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expand-pack-3"},[_c('div',{staticClass:"expand-wrap"},[_c('img',{attrs:{"src":"/jord/chameleon-banner.png","alt":"Marketing banner"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('div',{staticClass:"central"},[_c('p',[_vm._v(" We've been doing what we do since 1995, that’s way before Google came on the scene. We’re a small team of creative designers and web developers. Small & friendly is good for us but we are more than capable at servicing "),_c('span',{staticClass:"big"},[_vm._v("BIG!")])]),_c('div',{staticClass:"view-more"},[_c('a',{attrs:{"href":"https://chameleoncreative.marketing","target":"_blank"}},[_vm._v("View our main website")])])])])
}]

export { render, staticRenderFns }