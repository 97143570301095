<template>
  <div>
    <section class="secondary">
      <div class="inner">
        <router-link class="link left" to="/work/sypf" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="/projects/sypf/main.jpg" alt="Web design and members app" />
          <span class="link-main">Web design &amp; members app</span>
          <span class="link-sub">
            <span>Transforming interaction for South Yorkshire Police Federation</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
        <router-link class="link right" to="/work/wortley-brothers" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="/projects/wb/main-new.jpg" alt="Web design, branding, illustration, e-marketing" />
          <span class="link-main">Branding, E-commerce website,<br />design &amp; print</span>
          <span class="link-sub">
            <span>Boosts online business for Wortley Brothers</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner">
        <router-link class="link full" to="/work/obsurvus" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img alt="Marketing toolkit for Obsurvus" src="../assets/obsurvus.jpg" />
          <span class="link-main">Branding, web design, design &amp; print</span>
          <span class="link-sub">
            <span>A new powerful, robust marketing toolkit for Obsurvus</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner">
        <router-link class="link full" to="/work/allsports" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="/projects/as/secondary.jpg" alt="Branding and packaging design" />
          <span class="link-main">Branding &amp; packaging design</span>
          <span class="link-sub">
            <span>Helping to get Allsports fit again</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["loc"])
  }
}
</script>

<style scoped>
.secondary {
  padding-top: 8rem;
  padding-bottom: 4rem;
}
.left,
.right {
  float: left;
  width: 48%;
  transition: all 1s;
  margin: 2.5rem 0;
}
.left {
  margin-right: 4%;
}
.link > span {
  display: block;
}
.link img {
  margin-bottom: 2rem;
}
.link-main {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 1rem;
  font-family: "Arvo", serif;
  font-weight: 700;
}
.link-sub {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.link-more > span {
  font-size: 2rem;
  background: #e7e8e9;
  padding: 1rem 2rem;
  display: inline-block;
  font-family: "Arvo", serif;
  font-weight: 700;
}
.left,
.full {
  transform: translateY(5rem);
}
.right {
  transform: translateY(10rem);
}
.in-viewport.link {
  transform: translateY(0) !important;
}
.full {
  margin-top: 10rem;
  margin-bottom: 5rem;
  display: block;
  clear: both;
  transition: all 1s;
}
.full img {
  width: 100%;
}

.heading {
  font-family: "Arvo", serif;
  font-weight: 700;
  font-size: 5rem;
  margin-bottom: 2rem;
}
.list {
  list-style-type: none;
  font-size: 2.5rem;
  padding: 0;
  padding-bottom: 4rem;
}
.list li {
  margin: 1rem 0;
}
.buzz > div {
  display: inline-block;
  background: #e7e8e9;
  padding: 2rem;
  font-size: 3rem;
}
.buzz a {
  font-size: 6rem;
  font-family: "Arvo", serif;
  font-weight: 900;
}
.nopad {
  padding-top: 0;
  margin-top: 0;
}
@media screen and (max-width: 650px) {
  .secondary {
    padding-top: 0;
    padding-bottom: 2rem;
  }
  .link,
  .link.left {
    width: 100%;
    margin: auto;
    margin: 2rem auto;
  }
  .buzz a {
    font-size: 5rem;
  }
  .heading {
    font-size: 4rem;
  }
}
</style>
