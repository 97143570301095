<template>
  <div class="clients" v-if="$store.state.about_page && $store.state.about_page.testimonials.length">
    <div class="inner">
      <div class="clients-heading central">Clients say the nicest things</div>
      <div class="quotes">
        <p :key="testimonial._id" v-for="testimonial in $store.state.about_page.testimonials">
          "{{ testimonial.quote }}"
          <span>{{ testimonial.company }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page"])
  }
}
</script>

<style scoped>
.clients {
  background: #e7e8e9;
  padding: 8rem 0;
  margin: 8rem 0;
}
.clients-heading {
  font-size: 5rem;
  padding-bottom: 5rem;
}
.clients img {
  margin-bottom: 10rem;
}
.central {
  text-align: center;
}
.central .clients-heading {
  max-width: unset;
  padding-bottom: 0;
}
.quotes p {
  margin: auto;
  font-size: 2rem;
  margin-bottom: 5rem;
  line-height: 1.5;
  text-align: center;
  max-width: 110rem;
}
.quotes p:last-child {
  margin-bottom: 0;
}
.quotes p:last-child::after {
  display: none;
}
.quotes p::after {
  display: block;
  width: 30%;
  content: " ";
  height: 1px;
  background: white;
  margin: auto;
  margin-top: 5rem;
}
.quotes span {
  display: block;
}
@media screen and (max-width: 800px) {
  .clients-heading {
    font-size: 4rem;
  }
}
@media screen and (max-width: 750px) {
  .clients {
    padding: 5rem 0;
    margin: 5rem 0;
  }
  .clients-heading {
    padding-bottom: 6rem;
  }
  .clients img {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .clients-heading {
    font-size: 3rem;
  }
}
</style>
