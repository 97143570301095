<template>
  <section class="content">
    <div class="inner">
      <div v-html="page.text1"></div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page"])
  }
}
</script>

<style scoped>
.inner {
  padding-top: 20rem;
}
.inner >>> p,
.inner >>> h3,
.inner >>> ul {
  padding-bottom: 2rem;
  line-height: 1.5;
}
.inner >>> ul {
  padding-left: 2rem;
}
.inner >>> .heading {
  font-size: 5rem;
  padding-bottom: 4rem;
}
@media screen and (max-width: 600px) {
  .inner >>> .heading {
    font-size: 3rem;
  }
}
</style>
