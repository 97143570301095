<template>
  <section class="general">
    <div class="inner">
      <div class="container right" v-html="page.text1"></div>

      <img
        alt="25 big ones"
        class="celebrate"
        :src="settings.years_image"
        v-in-viewport.once="{ margin: '-20% 0px' }"
      />
      <div class="container">
        <div v-html="page.text2"></div>
        <router-link class="link-more" to="/what-we-do"> <span>read more</span> </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["settings"]),
    page() {
      return this.$store.state.home_page || {}
    }
  }
}
</script>

<style scoped>
.general {
  margin-bottom: -5rem;
}
.container {
  padding-top: 5rem;
}
.celebrate {
  float: right;
  width: 50rem;
  transform: translateY(2rem) scale(0.8) rotate(10deg);
  max-width: 40%;
  margin-left: 5rem;
  margin-top: 10rem;
  transition: all 1s;
}
.celebrate.in-viewport {
  transform: translateY(2rem) scale(1) rotate(0);
}
.right {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  font-size: 3.5rem;
  padding-bottom: 5rem;
  line-height: 1.5;
}
.right >>> strong {
  font-family: "Arvo", serif;
  font-weight: 700;
  transform: scale(1.2);
  margin-left: 1rem;
  display: inline-block;
}
.inner >>> .heading {
  font-family: "Arvo", serif;
  font-weight: 700;
  font-size: 5rem;
  margin-bottom: 2rem;
}
.inner >>> .list {
  list-style-type: none;
  font-size: 2.5rem;
  padding: 0;
  padding-bottom: 4rem;
}
.inner >>> .list li {
  margin: 1rem 0;
}
.inner >>> .link-more > span {
  font-size: 2rem;
  background: #e7e8e9;
  padding: 1rem 2rem;
  display: inline-block;
  font-family: "Arvo", serif;
  font-weight: 700;
}
@media screen and (max-width: 1100px) {
  .right {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media screen and (max-width: 850px) {
  .celebrate {
    display: block;
    float: none;
    margin: auto;
    max-width: 60%;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 650px) {
  .right {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    font-size: 2.5rem;
  }
  .heading {
    font-size: 4rem;
  }
  .list {
    font-size: 2rem;
  }
  .general {
    padding-bottom: 4rem;
  }
}
</style>
