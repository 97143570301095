<template>
  <section
    :class="
      $route.name == 'index' || $route.name == 'web-design' || $route.name == 'web-design-landing' ? 'minheight' : ''
    "
    class="intro"
  >
    <div class="inner">
      <template v-if="$route.name == 'index'">
        <vue-typed-js
          :backDelay="1500"
          :backSpeed="0"
          :loop="true"
          :strings="['creative <span>expertise</span>', 'real results', '<strong>your success.</strong>']"
        >
          <h1>
            <span>
              We're the
              <span>marketing agency</span>
            </span>
            <span>that delivers</span>
            <span class="typing"></span>
          </h1>
        </vue-typed-js>
        <span v-if="loc !== 'd' && loc !== 'r'" v-html="page.intro_text"></span>
        <span v-if="loc == 'd'"
          ><p>
            A down to earth marketing and web design agency, perfectly located in Doncaster, South Yorkshire. We create,
            we deliver, we communicate and we build strong relationships with our clients.
          </p></span
        >
        <span v-if="loc == 'r'"
          ><p>
            A down to earth marketing and web design agency, perfectly located in North Nottinghamshire. We create, we
            deliver, we communicate and we build strong relationships with our clients.
          </p></span
        >
      </template>
      <template v-if="$route.name == 'small-business'">
        <vue-typed-js
          :backDelay="1500"
          :backSpeed="0"
          :loop="true"
          :strings="['small businesses', 'retail shops', 'tradesmen']"
        >
          <h1 class="smaller" style="max-width:1500px">
            <span>
              We're the local
              <span>design agency for</span>
            </span>
            <span class="typing"></span>
          </h1>
        </vue-typed-js>
        <p>
          A down to earth creative agency based in Retford, Nottinghamshire. We design, we develop, we communicate and
          we build strong relationships with our clients.
        </p>
        <div class="down bounce">
          <a @click.prevent="scrollFix('#anchor')" class href>
            <font-awesome-icon icon="chevron-down" />
          </a>
          <div id="anchor"></div>
        </div>
      </template>
      <template v-if="$route.name == 'web-design'">
        <vue-typed-js
          :backDelay="1500"
          :backSpeed="0"
          :loop="true"
          :strings="[
            'online <span>expertise</span>',
            'awesome <span>websites</span>',
            'e-commerce <span>excellence</span>'
          ]"
        >
          <h1 style="max-width:1500px">
            <span>
              We're the
              <span>design agency</span>
            </span>
            <span>who offer</span>
            <span class="typing"></span>
          </h1>
        </vue-typed-js>
        <p>
          A down to earth creative and web design agency. We create, we deliver, we communicate and we build strong
          relationships with our clients.
        </p>
      </template>
      <template v-if="$route.name == 'web-design-landing'">
        <vue-typed-js
          :backDelay="1500"
          :backSpeed="0"
          :loop="true"
          :strings="[
            'digital <span>expertise</span>',
            'awesome <span>websites</span>',
            'amazing <span>brands</span>',
            '<strong>your success.</strong>'
          ]"
        >
          <h1>
            <span>
              We're the
              <span>marketing agency</span>
            </span>
            <span>that delivers</span>
            <span class="typing"></span>
          </h1>
        </vue-typed-js>
        <p>
          A down to earth marketing and web design agency
          <span v-if="$route.params.location !== 'doncaster' && $route.params.location !== 'retford'">near</span>
          <span v-else>in</span>
          <span class="capitalise">{{ $route.params.location }}</span
          >. We create, we deliver, we communicate and we’ve been building strong relationships with our clients for the
          past 26 years.
        </p>
      </template>

      <template v-if="isAboutPage || isWorkPage || isWhatWeDoPage">
        <h1>{{ page.intro_heading }}</h1>
        <span v-html="page.intro_text"></span>
      </template>

      <template v-if="work_page && $route.name == 'design-agency'">
        <h1>{{ work_page.intro_heading }}</h1>
        <span v-html="work_page.intro_text"></span>
      </template>

      <template v-if="isContactPage">
        <h1>{{ page.intro_heading }}</h1>
        <span v-html="page.intro_text"></span>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page", "work_page", "loc"]),
    ...mapGetters(["isHomePage", "isWorkPage", "isSmallBusinessPage", "isWhatWeDoPage", "isAboutPage", "isContactPage"])
  },
  methods: {
    scrollFix: function(hash) {
      document.getElementById(hash.replace("#", "")).scrollIntoView({ behavior: "smooth" })
    }
  }
}
</script>

<style scoped>
.intro.minheight {
  min-height: 80vh;
}
.intro {
  min-height: 40vh;
  padding: 14rem 0;
  padding-bottom: 11rem;
  margin-top: 10rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.intro >>> h1 {
  font-size: 8.5rem;
  margin-bottom: 3rem;
}
.intro >>> h1.smaller {
  font-size: 5.5rem;
}
.intro h1 >>> strong {
  background: #ffea2c;
}
.intro >>> p {
  max-width: 1150px;
  font-size: 3.2rem;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 3rem;
}
.typing {
  display: inline-block;
  min-width: 10rem;
}
.down {
  text-align: center;
  font-size: 5rem;
  position: relative;
  bottom: -8rem;
}

.contact {
  display: inline-block;
  float: right;
  background: #e7e8e9;
  padding: 1rem 2rem;
  font-size: 4rem;
  font-family: "Arvo", serif;
  font-weight: 900;
}

span.capitalise,
h1 > span >>> span.capitalise {
  text-transform: capitalize;
  display: inline !important;
}

@media screen and (max-width: 2000px) {
  .intro.minheight {
    min-height: 60rem;
  }
}
@media screen and (max-width: 1600px) {
  .intro >>> h1 {
    font-size: 7.5rem;
    margin-bottom: 2.5rem;
  }
  .intro >>> h1.smaller {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 1200px) {
  h1 > span {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .intro >>> h1 {
    font-size: 6rem;
    margin-bottom: 2rem;
  }
  .intro >>> h1.smaller {
    font-size: 3.5rem;
  }
  .intro >>> p {
    font-size: 2.5rem;
  }
  .contact {
    display: none;
  }
  .intro.minheight h1 {
    height: 45rem;
  }
  h1 > span >>> span {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .intro >>> h1 {
    font-size: 4.5rem;
  }
  .intro >>> h1.smaller {
    font-size: 4rem;
  }
  .intro {
    padding-bottom: 0;
  }

  .down {
    bottom: -4rem;
  }
  .intro.minheight h1 {
    height: 30rem;
  }
}

@media screen and (max-width: 435px) {
  .intro {
    padding-top: 2rem;
  }
  .intro.minheight h1 {
    min-height: 42rem;
  }
}
</style>
