<template>
  <section class="secondary">
    <template v-if="$route.name == 'design-agency'">
      <div class="inner">
        <router-link class="link full" to="/work/obsurvus" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="../assets/obsurvus.jpg" alt="Marketing toolkit for Obsurvus" />
          <span class="link-main">Marketing toolkit for Obsurvus</span>
          <span class="link-sub">
            <span>powerful, robust &amp; effective</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner">
        <router-link class="link left" to="/work/circuit-days" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="../assets/CD-brochure.jpg" alt="New marketing strategy" />
          <span class="link-main">New marketing strategy</span>
          <span class="link-sub">
            <span>sends Circuit Days into a spin</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
        <router-link class="link right" to="/work/uk-search" v-in-viewport.once="{ margin: '-30% 0px' }">
          <img src="../assets/uks.jpg" alt="Responsive website and payment portal<" />
          <span class="link-main">Responsive website &amp; payment portal</span>
          <span class="link-sub">
            <span>UK Search, seriously slick online services</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner">
        <router-link class="link full" to="/work/sypf" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="/projects/sypf/work.jpg" alt="App and website" />
          <span class="link-main">Responsive website &amp; members app</span>
          <span class="link-sub">
            <span>a 'tour de force' for South Yorkshire cops</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner">
        <router-link class="link left" to="/work/fullgasclub" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img alt="App development" src="../assets/fgc.jpg" />
          <span class="link-main">App development</span>
          <span class="link-sub">
            <span>FullgasClub... the ultimate car buying app</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
        <router-link class="link right" to="/work/wortley-brothers" v-in-viewport.once="{ margin: '-30% 0px' }">
          <img alt="E-commerce website design" src="/projects/wb/WB-wood-logo.jpg" />
          <span class="link-main">E-commerce website &amp; branding</span>
          <span class="link-sub">
            <span>boosts online business for Wortley Brothers</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
    </template>
    <template v-else>
      <div class="inner">
        <router-link class="link left" to="/work/sypf" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="/projects/sypf/main.jpg" alt="Web design and members app" />
          <span class="link-main">Web design &amp; members app</span>
          <span class="link-sub">
            <span>Transforming interaction for South Yorkshire Police Federation</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
        <router-link class="link right" to="/work/circuit-days" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="/projects/cd/main.jpg" alt="Web design, branding, illustration, e-marketing" />
          <span class="link-main">Web design, branding, design &amp; print</span>
          <span class="link-sub">
            <span>Powering Circuit Days - The UK’s leading track day provider</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner">
        <router-link class="link left" to="/work/wortley-brothers" v-in-viewport.once="{ margin: '-30% 0px' }">
          <img src="/projects/wb/WB-wood-logo.jpg" alt="E-commerce website and branding " />
          <span class="link-main">Branding, E-commerce website, design &amp; print</span>
          <span class="link-sub">
            <span>Boosts online business for Wortley Brothers</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
        <router-link class="link right" to="/work/fullgasclub" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="../assets/fgc.jpg" alt="App development and  web design" />
          <span class="link-main">App development &amp; web design</span>
          <span class="link-sub">
            <span>FullgasClub... the ultimate car buying app</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner">
        <router-link class="link full" to="/work/obsurvus" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="../assets/obsurvus.jpg" alt="Branding, web design, design and print" />
          <span class="link-main">Branding, web design, design &amp; print</span>
          <span class="link-sub">
            <span>A new powerful, robust marketing toolkit for Obsurvus</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner">
        <router-link class="link left" to="/work/allsports" v-in-viewport.once="{ margin: '-30% 0px' }">
          <img src="/projects/as/main.jpg" alt="Branding and packaging design" />
          <span class="link-main">Branding &amp; packaging design</span>
          <span class="link-sub">
            <span>Helping to get Allsports fit again</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
        <router-link class="link right" to="/work/funeral-directors" v-in-viewport.once="{ margin: '-30% 0px' }">
          <img src="/projects/fd/main-1.jpg" alt="Services for Funeral Directors" />
          <span class="link-main">Services for Funeral Directors</span>
          <span class="link-sub">
            <span>Tailored creative marketing services for your sector</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
      <div class="inner paddedtop">
        <router-link class="link full" to="/work/lincoln-welding" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img src="../assets/lw-our-work.jpg" alt="Lincoln Welding website" />
          <span class="link-main">E-commerce web design</span>
          <span class="link-sub">
            <span>Ignites online sales for Lincoln Welding</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
    </template>
  </section>
</template>

<script>
export default {}
</script>

<style scoped>
.secondary {
  padding-top: 8rem;
  padding-bottom: 4rem;
}
.left,
.right {
  float: left;
  width: 48%;
  transition: all 1s;
}
.left {
  margin-right: 4%;
}
.link > span {
  display: block;
}
.link img {
  margin-bottom: 2rem;
}
.link-main {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 1rem;
  font-family: "Arvo", serif;
  font-weight: 700;
}
.link-sub {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.link-more > span {
  font-size: 2rem;
  background: #e7e8e9;
  padding: 1rem 2rem;
  display: inline-block;
  font-family: "Arvo", serif;
  font-weight: 700;
}
.left,
.full {
  transform: translateY(5rem);
}
.right {
  transform: translateY(10rem);
}
.in-viewport.link {
  transform: translateY(0) !important;
}
.full {
  display: block;
  clear: both;
  transition: all 1s;
}
.inner {
  padding-bottom: 10rem;
}
.full img {
  width: 100%;
}
.inner:last-child {
  padding-bottom: 0;
}

@media screen and (max-width: 650px) {
  .secondary {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
  .link,
  .link.left {
    width: 100%;
    margin: auto;
    margin: 2rem auto;
    margin-bottom: 5rem;
  }
  .inner {
    padding-bottom: 0;
  }
}
</style>
