<template>
  <section class="content">
    <div class="inner">
      <div class="split-text" v-html="page.text1"></div>

      <img
        alt="25 big ones"
        class="celebrate"
        :src="settings.years_image"
        v-in-viewport.once="{ margin: '-20% 0px' }"
      />
    </div>

    <div class="inner team">
      <span v-html="page.text2"></span>
      <div class="profiles" v-if="page.team && page.team.length">
        <div :key="profile.name" class="profile" v-for="profile in page.team">
          <img :alt="profile.name" :src="profile.image" />
          <div class="profile-heading">{{ profile.name }}</div>

          <div class="profile-desc" v-html="profile.bio"></div>
        </div>
      </div>
    </div>
    <clients-elem></clients-elem>
    <div class="inner" v-html="page.text3"></div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import ClientsElem from "@/components/Clients.vue"

export default {
  computed: {
    ...mapState(["page", "settings"])
  },
  components: {
    ClientsElem
  }
}
</script>

<style scoped>
.view-more {
  font-size: 3rem;
  padding-top: 5rem;
}
.view-more a {
  border: 1px solid #f0f0f0;
  padding: 1rem 2rem;
  font-family: "Arvo", serif;
  font-weight: 700;
  transition: all 0.3s;
  background: linear-gradient(to right, #fff 50%, #f5f5f5 50%);
  background-size: 200% 100%;
}
.split-text {
  float: left;
  width: 57.5%;
  font-size: 2.5rem;
  font-weight: 300;
}
.split-text >>> p,
.text >>> p {
  margin-bottom: 2rem;
  line-height: 1.5;
}
.team {
  font-size: 2.5rem;
  font-weight: 300;
  padding-top: 8rem;
}
.profiles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15rem;
  grid-row-gap: 5rem;
  margin-top: 3rem 0;
}
.profile img {
  width: 60%;
  margin: 5rem auto;
  display: block;
}
.profile-desc {
  font-size: 2rem;
}
.profile-desc >>> p {
  padding-top: 2rem;
}
.celebrate {
  float: left;
  width: 50rem;
  transform: translateY(2rem) scale(0.8) rotate(10deg);
  max-width: 40%;
  margin-left: 5%;
  margin-top: 6rem;
  transition: all 1s;
}
.celebrate.in-viewport {
  transform: translateY(2rem) scale(1) rotate(0);
}
.clients {
  background: #e7e8e9;
  padding: 8rem 0;
  margin: 8rem 0;
}
.clients-heading {
  font-size: 5rem;
  padding-bottom: 5rem;
}
.clients img {
  margin-bottom: 10rem;
}
.central {
  text-align: center;
}
.central .clients-heading {
  max-width: unset;
  padding-bottom: 0;
}
.quotes p {
  margin: auto;
  font-size: 2rem;
  margin-bottom: 5rem;
  line-height: 1.5;
  text-align: center;
  max-width: 110rem;
}
.quotes p:last-child {
  margin-bottom: 0;
}
.quotes p:last-child::after {
  display: none;
}
.quotes p::after {
  display: block;
  width: 30%;
  content: " ";
  height: 1px;
  background: white;
  margin: auto;
  margin-top: 5rem;
}
.quotes p:nth-child(even) {
}
.quotes span {
  display: block;
}
.inner >>> .service-heading {
  font-size: 5rem;
  padding-bottom: 5rem;
}
.inner >>> .service-columns ul {
  list-style-type: none;
  columns: 2;
  font-size: 2rem;
  font-weight: 300;
  column-gap: 15rem;
}

.inner >>> .service-columns li {
  padding: 1rem 0;
}

.content >>> .heading {
  font-size: 5rem;
  padding-bottom: 4rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .profiles > * {
    width: 45%;
    margin-right: 5%;
    float: left;
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 1200px) {
  .profiles {
    grid-column-gap: 8rem;
  }
  .inner >>> .service-columns ul {
    column-gap: 8rem;
  }
}
@media screen and (max-width: 800px) {
  .clients-heading,
  .inner >>> .service-heading {
    font-size: 4rem;
  }
}
@media screen and (max-width: 750px) {
  .celebrate {
    display: block;
    float: none;
    margin: auto;
    max-width: 60%;
    margin-bottom: 4rem;
  }
  .split-text {
    font-size: 2rem;
    width: 100%;
    max-width: 100%;
    float: none;
    margin-right: 0;
  }
  .split-text {
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  .clients {
    padding: 5rem 0;
    margin: 5rem 0;
  }
  .clients-heading {
    padding-bottom: 6rem;
  }
  .clients img {
    margin-bottom: 5rem;
  }
  .profiles {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 600px) {
  .content >>> .heading,
  .clients-heading,
  .inner >>> .service-heading {
    font-size: 3rem;
  }
  .inner >>> .service-columns ul {
    columns: unset;
  }
}
</style>
