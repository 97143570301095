<template>
  <section class="content" id="content">
    <div class="contrast">
      <div class="inner">
        <div class="text-left">
          <div class="heading">what we do best</div>
          <ul class="list">
            <li>Branding &amp; logo design</li>
            <li>Web design</li>
            <li>Graphic design</li>
            <li>Printing</li>
            <li>Digital marketing</li>
            <li>E-campaigns</li>
            <li>Social media</li>
            <li>Vehicle graphics</li>
            <li>Signs and displays</li>
          </ul>
        </div>
        <div class="expand-best">
          <div class="expand-best-1">
            <img src="../assets/celebrating.png?v=2" alt="25 years" />
          </div>
          <div class="expand-best-2">
            <img src="/jord/pga-iphone.png" alt="Responsive web design" />
          </div>
          <div class="expand-best-3">
            <img src="/jord/worksop-web-design-5.jpg" alt="Business card design" />
            <img src="/jord/retford-web-designers-4.jpg" alt="Vehicle signage" />
          </div>
        </div>
      </div>
    </div>
    <div class="inner">
      <div class="split-text">
        <div class="heading">Contact Chameleon Creative</div>
        <p>
          Hi there, I’m Jordan and I’m your ﬁrst point of contact if you’re looking to get your business off the ground
          or you want to improve your current business marketing perception.
        </p>
        <p>
          Being part of Chameleon Creative is great for me as I get the opportunity to work alongside a fantastic
          creative team but I am able to focus on my own clients in and around Retford, Worksop and North
          Nottinghamshire.
        </p>
        <p>I specialise in offering small local businesses the best marketing at the most competitive prices.</p>
        <p>Why not give me a call or drop me an email and we can chat about how we can help.</p>
        <div class="sub-heading">
          Jordan Reep
          <br />
          <a href="tel:01777553443">01777 553443</a> /
          <a href="tel:07501977709">07501 977709</a>
          <br />
          <a href="mailto:jordan@chameleon-creative.co.uk">jordan@chameleon-creative.co.uk</a>
        </div>
      </div>

      <img
        v-in-viewport="{ margin: '-20% 0px' }"
        src="/profiles/jord-colour.jpg"
        alt="Contact Jordan"
        class="split-image"
      />
    </div>
    <div class="contrast">
      <div class="inner">
        <div class="text-right">
          <div class="heading">Logo design from £250</div>
          <p>
            Every business needs a logo, a brand, an identity in order to get noticed and remembered. We'll develop your
            perfect brand and help you promote it so that you stand out against the competition.
          </p>
        </div>

        <div class="expand-logos" v-in-viewport.once="{ margin: '-20% 0px' }">
          <img class="expand-logos-1" src="/jord/PG-logo-final-copy.png" alt="Logo design" />
          <img class="expand-logos-2" src="/jord/Oak_logo.png" alt="Logo designer" />
          <img class="expand-logos-3" src="/jord/Allsports-logo.png" alt="Graphic design" />
          <img class="expand-logos-3" src="/jord/debonair.jpg" alt="Company logo design" />
        </div>
      </div>
    </div>

    <div class="padded">
      <div class="inner">
        <div class="text-left">
          <div class="heading">Website design from £599</div>
          <p>
            We make sure that your website, regardless of its size or complexity draws your clients in, encourages them
            to find out more and have an enjoyable experience.
          </p>

          <p>
            Every website we develop is fully responsive so it adapts to any device. We also provide web hosting and
            domain name registration.
          </p>
        </div>
        <div class="expand-web" v-in-viewport="{ margin: '-20% 0px' }">
          <img class="expand-web-1" src="/jord/PG.png" alt="Responsive web page" />
          <img class="expand-web-2" src="/jord/oak1.png" alt="Website development" />
          <img class="expand-web-3" src="/jord/tjprince-mobile.png" alt="Mobile web design" />
        </div>
      </div>
    </div>
    <div class="contrast">
      <div class="inner">
        <div class="text-right">
          <div class="heading">
            Special offer:
            <br />marketing pack £1,599
          </div>
          <p>
            This is an amazing deal and will get you marketing your business professionally and affordably, the pack
            consist of:
          </p>
          <p>
            <strong>
              Logo design, 250 full colour business cards, 5000 full colour ﬂyers, vehicle graphics (small/medium van),
              1 roller banner and a responsive web page including web hosting for 1 year.
            </strong>
          </p>
        </div>

        <div class="expand-pack" v-in-viewport.once="{ margin: '-20% 0px' }">
          <div class="expand-pack-1">
            <div class="expand-wrap">
              <img src="/jord/Oak-electrical-concepts.jpg" alt="Logo concepts" />
              <img src="/jord/tjp-b.jpg" alt="Business cards" />
              <img src="/jord/allsports.jpg" alt="Vehicle signage" />
            </div>
          </div>
          <div class="expand-pack-2">
            <div class="expand-wrap">
              <img src="/jord/pgalloys.jpg" alt="Brochure design" />
              <img src="/jord/ipad_oak.jpg" alt="Responsive website" />
            </div>
          </div>
          <div class="expand-pack-3">
            <div class="expand-wrap">
              <img src="/jord/chameleon-banner.png" alt="Marketing banner" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner">
      <div class="central">
        <p>
          We've been doing what we do since 1995, that’s way before Google came on the scene. We’re a small team of
          creative designers and web developers. Small &amp; friendly is good for us but we are more than capable at
          servicing
          <span class="big">BIG!</span>
        </p>
        <div class="view-more">
          <a href="https://chameleoncreative.marketing" target="_blank">View our main website</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped>
.content {
  font-size: 2.5rem;
  font-weight: 300;
}
.view-more {
  font-size: 3rem;
  padding-top: 5rem;
}
.view-more a {
  border: 1px solid #f0f0f0;
  padding: 1rem 2rem;
  font-family: "Arvo", serif;
  font-weight: 700;
  transition: all 0.3s;
  background: linear-gradient(to right, #fff 50%, #f5f5f5 50%);
  background-size: 200% 100%;
}
.split-text {
  float: left;
  width: 57.5%;
  padding-top: 8rem;
}
.padded {
  padding-top: 8rem;
}
.split-text p,
.text-left p,
.text-right p {
  margin-bottom: 2rem;
  line-height: 1.5;
}
.split-image {
  float: left;
  width: 50rem;
  transform: translateY(2rem) scale(0.8) rotate(10deg);
  max-width: 40%;
  margin-left: 5%;
  margin-top: 6rem;
  transition: all 1s;
}
.split-image.in-viewport {
  transform: translateY(2rem) scale(1) rotate(0);
}

.heading {
  font-size: 5rem;
  padding-bottom: 4rem;
}

.sub-heading {
  font-size: 3.5rem;
}

.central {
  padding-top: 8rem;
  padding-bottom: 0;
  text-align: center;
}

.central p {
  max-width: 1000px;
  margin: auto;
}

.view-more {
  font-size: 3rem;
  padding-top: 5rem;
}
.view-more a {
  border: 1px solid #f0f0f0;
  padding: 1rem 2rem;
  font-family: "Arvo", serif;
  font-weight: 700;
  transition: all 0.3s;
  background: linear-gradient(to right, #fff 50%, #f5f5f5 50%);
  background-size: 200% 100%;
}

.contrast {
  background: #e7e8e9;
  padding: 5rem 0;
  margin-top: 8rem;
}

.text-right {
  text-align: right;
  max-width: 850px;
  float: right;
}
.text-left {
  max-width: 850px;
}

.list {
  list-style-type: none;
}

.expand-web {
  position: relative;
  margin-top: 8rem;
}
.expand-web-1 {
  position: absolute;
  top: 0;
  left: 30%;
  z-index: 1;
  max-width: 30%;
  transition: all 1.3s;
  transform: scale(0.8);
}
.expand-web-2 {
  position: relative;
  z-index: 3;
  margin: auto;
  display: block;
  max-width: 50%;
  padding-top: 10rem;
  transition: all 1.3s;
  transform: scale(0.5);
}
.expand-web-3 {
  position: absolute;
  top: -10rem;
  right: 30%;
  z-index: 1;
  max-width: 20%;
  transition: all 1.3s;
  transform: scale(0.8);
}

.expand-web.in-viewport .expand-web-1 {
  left: 0;
  transform: scale(1);
}
.expand-web.in-viewport .expand-web-2 {
  transform: scale(1);
}
.expand-web.in-viewport .expand-web-3 {
  right: 9%;
  transform: scale(1);
}

.expand-logos {
  display: grid;
  clear: both;
  width: 80%;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  padding-top: 8rem;
  transition: all 1.3s;
  transform: scale(0.8);
  grid-gap: 15rem;
  grid-row-gap: 7.5rem;
}

.expand-logos.in-viewport {
  transform: scale(1);
}

.expand-best {
  position: relative;
  float: right;
  width: 100%;
  top: -25rem;
  margin-bottom: -20rem;
  display: grid;
  grid-template-columns: 2fr 3fr 4.35fr;
  grid-gap: 5rem;
}

.expand-best-1 {
  display: flex;
  align-items: flex-end;
}
.expand-best-3 img:first-child {
  margin-bottom: 2rem;
}

.expand-pack {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1.5fr;
  padding-top: 0rem;
  transition: all 1.3s;
  transform: scale(0.8);
  grid-gap: 2rem;
}

.expand-pack.in-viewport {
  grid-gap: 5rem;
  transform: scale(1);
}

.expand-pack > div {
  display: flex;
  align-items: flex-end;
}

.expand-pack img {
  margin-bottom: 3rem;
}

@media screen and (max-width: 1500px) {
  .expand-web-3 {
    top: 0;
    right: 0;
  }
  .expand-web.in-viewport .expand-web-3 {
    right: 0;
  }
  .expand-pack {
    padding-top: 3rem;
  }
}
@media screen and (max-width: 1100px) {
  .expand-best {
    top: 0;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 750px) {
  .split-image {
    display: block;
    float: none;
    margin: auto;
    max-width: 60%;
    margin-bottom: 4rem;
  }
  .split-text {
    width: 100%;
    max-width: 100%;
    float: none;
    margin-right: 0;
    padding-top: 2rem;
  }
  .split-text {
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  .content {
    font-size: 2rem;
  }
  .padded {
    padding-top: 5rem;
  }
  .expand-web-1 {
    max-width: 50%;
  }
  .expand-web-3 {
    max-width: 35%;
  }
  .expand-web-2 {
    padding-top: 20rem;
    max-width: 75%;
  }

  .expand-best {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
  .expand-best-1 {
    display: none;
  }
  .expand-pack.in-viewport {
    grid-gap: 2.5rem;
  }
  .expand-logos {
    grid-gap: 5rem;
    grid-row-gap: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .heading {
    font-size: 3rem;
  }
  .sub-heading {
    font-size: 2rem;
  }

  .view-more {
    font-size: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .expand-pack {
    grid-template-columns: 1fr;
  }
}
</style>
