<template>
  <section class="content">
    <div class="inner">
      <img :src="page.image1" alt="Alt tag" class="split-image" v-if="page.image1" />
      <div class="split-text" v-html="page.text1"></div>
    </div>
    <div class="clients">
      <div class="inner">
        <div class="clients-heading">Clients help to make the magic happen</div>
        <img :src="page.image2" alt="Some of our clients" v-if="page.image2" />
      </div>
    </div>
    <div class="inner" v-html="page.text2"></div>
  </section>
</template>

<script>
export default {
  data() {
    return { phone: "" }
  },
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>

<style scoped>
.view-more,
.split-text >>> .view-more {
  font-size: 3rem;
  padding-top: 5rem;
}
.view-more a,
.split-text >>> .view-more a {
  border: 1px solid #f0f0f0;
  padding: 1rem 2rem;
  font-family: "Arvo", serif;
  font-weight: 700;
  transition: all 0.3s;
  background: linear-gradient(to right, #fff 50%, #f5f5f5 50%);
  background-size: 200% 100%;
}
.split-text {
  float: left;
  width: 47.5%;
  font-size: 2.5rem;
  font-weight: 300;
  text-align: right;
}
.split-text >>> p,
.text p {
  margin-bottom: 2rem;
  line-height: 1.5;
}
.split-image {
  float: left;
  max-width: 47.5%;
  margin-right: 5%;
}
.clients {
  background: #e7e8e9;
  padding: 8rem 0;
  margin: 8rem 0;
}
.clients-heading {
  font-size: 5rem;
  max-width: 65rem;
  padding-bottom: 10rem;
}
.clients img {
  display: block;
  margin: auto;
  margin-bottom: 10rem;
}
.central {
  text-align: center;
}
.inner >>> .central .clients-heading {
  max-width: unset;
  padding-bottom: 0;
}
.clients .view-more a:hover {
  padding: 2rem 4rem;
  background-position: left bottom !important;
}
.inner >>> .service-heading {
  font-size: 5rem;
  padding-bottom: 5rem;
}
.inner >>> .service-columns ul {
  list-style-type: none;
  columns: 2;
  font-size: 2rem;
  font-weight: 300;
}

.inner >>> .service-columns li {
  padding: 1rem 0;
}

@media screen and (max-width: 800px) {
  .inner >>> .clients-heading,
  .inner >>> .service-heading {
    font-size: 4rem;
  }
}
@media screen and (max-width: 750px) {
  .split-text {
    font-size: 2rem;
  }
  .row img {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 3rem;
  }
  .split-text,
  .split-image {
    width: 100%;
    max-width: 100%;
    float: none;
    margin-right: 0;
  }
  .split-text {
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  .inner >>> .view-more {
    font-size: 2rem;
  }
  .clients {
    padding: 5rem 0;
    margin: 5rem 0;
  }
  .inner >>> .clients-heading {
    padding-bottom: 6rem;
  }
  .clients img {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .inner >>> .clients-heading,
  .inner >>> .service-heading {
    font-size: 3rem;
  }
  .inner >>> .service-columns ul {
    columns: unset;
  }
}
</style>
