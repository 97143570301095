<template>
  <section class="project">
    <div class="inner">
      <div class="heading" v-html="project.name.replace('>', '<br />')"></div>
      <div class="intro" v-html="project.intro"></div>
    </div>
    <div class="rows">
      <template v-for="row in project.rows">
        <div
          :key="row.sequence"
          class="row"
          :class="[row.fadeIn ? 'fadein' : '', row.backgroundFull ? 'extramargin' : '', row.class]"
          :style="{ gridArea: row.sequence, color: row.color ? row.color : '', padding: row.padding }"
          v-in-viewport="{ margin: row.fadeDelay ? row.fadeDelay : '200px 0px' }"
        >
          <div
            v-if="row.background"
            class="background"
            :class="[row.backgroundFull ? 'full' : '', row.edge == 'bottom' ? 'bottom' : '']"
            :style="{ background: row.background }"
          ></div>
          <div class="inner">
            <div :class="row.align == 'right' ? 'video-right' : 'video-left'" v-if="row.videoID">
              <div class="video" v-if="row.type == 'video'">
                <div class="embed-container">
                  <iframe
                    :src="
                      'https://player.vimeo.com/video/' +
                        row.videoID +
                        '?autoplay=1&loop=1&autopause=0&muted=1&background=1'
                    "
                    allow="autoplay"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="image" v-if="row.type == 'image'">
              <template v-for="(image, i) in row.images">
                <img
                  :key="image.src"
                  :src="image.src"
                  :alt="image.alt"
                  :width="image.width && i + 1 < row.images.length ? '' + (image.width - 5) + '%' : image.width + '%'"
                  :style="image.width && i + 1 < row.images.length ? 'margin-right:5%' : ''"
                  :class="image.anim"
                />
                <span class="caption" :class="image.captionClass" :key="'caption-' + image.src" v-if="image.caption">{{
                  image.caption
                }}</span>
              </template>
            </div>
            <div class="stacked" :class="'stacked-' + row.images.length" v-if="row.type == 'stacked'">
              <img
                :key="image.src"
                :src="image.src"
                :alt="image.alt"
                :width="image.width + '%'"
                v-for="image in row.images"
              />
            </div>
            <slide-show v-if="row.type == 'slideshow'">
              <div :key="image.src" v-for="image in row.images">
                <img :src="image.src" :alt="image.alt" :width="image.width + '%'" />
              </div>
            </slide-show>
            <template v-if="row.type == 'split'">
              <div class="split-text" v-html="row.text"></div>

              <div v-if="row.text2" class="split-text split-text-right" v-html="row.text2"></div>
              <template v-for="image in row.images">
                <img :src="image.src" :key="image.src" :alt="image.alt" class="split-image" />
                <span class="caption" :key="'caption-' + image.src" v-if="image.caption">{{ image.caption }}</span>
              </template>
              <div class="video-half" v-if="row.videoID">
                <div class="embed-container">
                  <iframe
                    :src="
                      'https://player.vimeo.com/video/' +
                        row.videoID +
                        '?autoplay=1&loop=1&autopause=0&muted=1&background=1'
                    "
                    allow="autoplay"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </template>
            <div class="scroller" v-if="row.type == 'scroller'">
              <img class="scroller-frame" :src="row.frame" :alt="row.name" />
              <img
                class="scroller-content"
                :src="image.src"
                :key="image.src"
                :alt="image.alt"
                v-for="image in row.images"
              />
            </div>
            <div
              class="text"
              :class="row.align == 'right' ? 'right' : ''"
              v-if="row.type == 'text'"
              v-html="row.text"
            ></div>
          </div>
        </div>
      </template>
    </div>
    <div class="view-more">
      <router-link to="/work">View more work</router-link>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      project: {},
      projects: [
        {
          name: `Wortley Brothers Wooden Gates`,
          intro: `<p>The new Wortley Brothers e-commerce website has been built from the ground up and features a vast number of custom developed features, including a product configurator.</p>`,
          rows: [
            {
              sequence: 1,
              fadeIn: false,
              type: "image",
              background: "#f79222",
              class: "adjust",
              images: [
                {
                  src: "/projects/wb/wb-desktop.jpg",
                  alt: "Wortley Brothers Website Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 2,
              fadeIn: true,
              type: "split",
              text: `<h2>How it began</h2><p>Wortley Brothers had used the same clunky e-commerce site for over ten years. Irrespective of the fact that they knew it needed updating, it worked for them, as long as a substantial Google Adwords budget was spent to help  it rank and obtain click throughs.</p><p>The team at Wortley Brothers had responded to a Chameleon e-shot and were honest about their scepticism, having approached several web design agencies over a three year period, all of whom had failed to deliver.</p><p>Challenge accepted... we started by developing a stronger brand and innovative marketing angle, which commenced with changing the company name from KP Wooden Gates to the more appealing Wortley Brothers Wooden Gates.</p><h2>What we've done...</h2><ul><li>Branding & logo creation </li><li>Web design & development </li><li>E-commerce </li><li>Custom Built CMS </li><li>Creative strategy & insight</li><li>Design and print </li><li>SEO  </li><li>Content writing </li><li>Web hosting and management </li></ul><button class='wb-btn nomobile'><img src='/projects/wb/ripple.svg'> Configure &amp; Buy Online</button><button class='wb-btn'><img src='/projects/wb/ripple.svg'> Add to cart</button>`,
              images: [
                {
                  src: "/projects/wb/wb-main-iphone.jpg",
                  alt: "Wortley Brothers Responsive Website",
                  width: 100
                }
              ]
            },
            {
              sequence: 3,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/wb/WB-wood.jpg",
                  width: 70,
                  alt: "Wortley Brothers Logo Design"
                },
                {
                  src: "/projects/wb/wb-branding.jpg",
                  width: 30,
                  alt: "Wortley Brothers Brand Design"
                }
              ]
            },
            {
              sequence: 4,
              fadeIn: true,
              type: "text",
              align: "right",
              text: `<p>
              Recognising the need for simplicity and a punchier appearance, we followed the re-brand with designing and developing  the custom built e-commerce website and Content Management System.</p>`
            },
            {
              sequence: 5,
              fadeIn: true,
              type: "stacked",
              images: [
                {
                  src: "/projects/wb/wb-phone-1.png",
                  alt: "Wortley Brothers Web Design",
                  width: 25
                },
                {
                  src: "/projects/wb/wb-phone-2.png",
                  alt: "Wortley Brothers Web Design",
                  width: 25
                },
                {
                  src: "/projects/wb/wb-phone-3.png",
                  alt: "Wortley Brothers Web Design",
                  width: 25
                }
              ]
            },
            {
              sequence: 6,
              fadeIn: true,
              type: "text",
              text: `<p>
              Integral to this was the streamlining of the copywriting; the previous site employed heavy copy content throughout - a big "no no" with Google. </p><p>This impressive online sales tool is proving to be a great success for one of the UK's largest wooden gate manufacturers, seeing online sales figures exceed 150K per month.</p>`
            },
            {
              sequence: 7,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/wb/wb-b.card.jpg",
                  alt: "Wortley Brothers Business Card Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 8,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/wb/wb-fonts.jpg",
                  alt: "Wortley Brothers Branding",
                  width: 100
                }
              ]
            }
          ]
        },
        {
          name: "FullgasClub",
          intro: `Never before has car buying been this good. Never before has a project pushed our boundaries so far...`,
          rows: [
            {
              sequence: 1,
              fadeIn: true,
              fadeDelay: "-20% 0px",
              background: "#00477F",
              type: "stacked",
              images: [
                {
                  src: "/projects/fgc/fgc-iphone-1.png",
                  alt: "Fullgasclub App Build",
                  width: 40
                },
                {
                  src: "/projects/fgc/fgc-iphone-2.png",
                  alt: "Fullgasclub App Build",
                  width: 40
                }
              ]
            },
            {
              sequence: 2,
              fadeIn: true,
              type: "text",
              align: "right",
              text: `<h2>How it began</h2><p>The FullgasClub team and Chameleon came together as a result of a recommendation. From the very first meeting, we knew this project had every opportunity to be exciting, rewarding and  demanding, but we didn’t fully appreciate just how far Chameleon would be challenged.</p>`
            },
            {
              sequence: 3,
              type: "image",
              fadeDelay: "-20% 0px",
              images: [
                {
                  src: "/projects/fgc/fgc-logo.jpg",
                  alt: "Fullgasclub Logo Design",
                  width: 100,
                  anim: "skew"
                }
              ]
            },
            {
              sequence: 4,
              fadeIn: true,
              type: "text",
              text: `<p>Chris, the brainchild behind Fullgas, really knows about cars, in fact we believe he eats, sleeps and continually dreams about them. The truth of the matter is, he had identified a gap in the market and he explained to Chameleon how his vision was going to fit right in, as long as he could find the perfect partner to work with.</p>`
            },
            {
              sequence: 5,
              fadeIn: false,
              background: "#00ADEF",
              type: "image",
              images: [
                {
                  src: "/projects/fgc/fgc-dealer-log-in.jpg",
                  alt: "Fullgasclub Web Development",
                  width: 100
                }
              ]
            },
            {
              sequence: 6,
              fadeIn: true,
              align: "right",
              type: "text",
              text: `<p>Most projects start at the beginning, however it was virtually impossible to physically find a place to start, given the complexity and diversity of the project.</p><p>We commenced by crafting out a work plan, not only for the brand but, working alongside the FGC team, we reviewed and assessed many of the current available apps that were already using the latest technologies and functionality to make searching and swiping fast and simple.</p><p>Many considerations were discussed, endless meetings held, and much coffee and cake consumed until we were all agreed that we had the right brand, colour scheme, concept and technical specification.</p>`
            },
            {
              sequence: 7,
              fadeIn: false,
              background: "#00ADEF",
              type: "image",
              images: [
                {
                  src: "/projects/fgc/admin-photo-stream.jpg",
                  alt: "Fullgasclub Web App Development",
                  width: 100
                }
              ]
            },
            {
              sequence: 8,
              fadeIn: true,
              type: "split",
              text: `<h2>What we’ve done...</h2><ul><li>Branding & logo creation</li><li>App design & development</li><li>Web design & development</li><li>Content management system</li><li>Dealer Portal</li><li>Admin Portal</li><li>Email marketing</li><li>Digital marketing</li><li>Design and print</li><li>SEO</li><li>Creative Strategy & Insight</li><li>Web hosting and management</li></ul>`,
              images: [
                {
                  src: "/projects/fgc/dealer-log-in.jpg",
                  alt: "Fullgasclub App developers",
                  width: 100
                }
              ]
            },
            {
              sequence: 9,
              fadeIn: false,
              type: "image",
              images: [
                {
                  src: "/projects/fgc/FGC-multi-iphones.jpg",
                  alt: "Fullgasclub Web Development",
                  width: 100
                }
              ]
            },
            {
              sequence: 10,
              fadeIn: true,
              type: "text",
              text: `<p>This has been like no other project we have ever worked on. The technical demands and innovative requirements set out by the Fullgas team pushed everything and everyone at Chameleon to their ultimate limits.</p><p>7 months on and the result... the best car buying App and online platform available anywhere in the UK today.</p><p><strong>Launching in Spring 2021!</strong></p>`
            },
            {
              sequence: 11,
              fadeIn: false,
              type: "image",
              background: "#00ADEF",
              images: [
                {
                  src: "/projects/fgc/fgc-dealer-manage.jpg",
                  alt: "Fullgasclub Web App",
                  width: 100
                }
              ]
            }
          ]
        },
        {
          name: "Obsurvus",
          intro: `Obsurvus required robust marketing tools to withstand the demands of their vigorous clients and that's exactly what they got.`,
          rows: [
            {
              sequence: 1,
              fadeIn: false,
              type: "image",
              background: "#00C0F2",
              class: "adjust",
              images: [
                {
                  src: "/projects/obsurvus/obsurvus-desktop.jpg",
                  alt: "Obsurvus Website Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 2,
              fadeIn: true,
              type: "text",
              align: "right",
              text:
                "<h2>How it began</h2><p>Never ones to back away from a challenge, we initially created a bold new brand for Obsurvus at the point the company restructured from PMC Surveys to Obsurvus, in 2015.</p>"
            },
            {
              sequence: 3,
              fadeIn: true,
              type: "split",
              background: "#00C0F2",
              backgroundFull: true,
              color: "#ffffff",
              text:
                "<p>This new identity and market focus further lead us to develop a powerful website which impacts on the user, illustrating the skills of the Obsurvus team in a fresh, accessible, visually stimulating way by utilising professional photography, creativity and great functionality.</p>",
              images: [
                {
                  src: "/projects/obsurvus/obsurvus-logo.jpg",
                  alt: "Obsurvus Logo Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 4,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/obsurvus/obsurvus-fonts.jpg",
                  alt: "Obsurvus Branding",
                  width: 100
                }
              ]
            },
            {
              sequence: 5,
              fadeIn: true,
              type: "text",
              text: `<p>Obsurvus are geo-surveying experts, a family firm with its roots securely based in Doncaster with clients from all over the country seeking their services, including some of Britain's largest mining and engineering companies like The Coal Authority and National Grid.</p> <p>Paul Munby, director of Obsurvus, wanted to be able to communicate his passion for his field of expertise whilst conveying the company professionalism, prowess and expertise.</p><p>We have now been working with the Obsurvus team for over 20 years, continually enjoying the seismic adventure every step of the way!</p>`
            },
            {
              sequence: 6,
              fadeIn: true,
              background: "#26ABE3",
              edge: "bottom",
              inset: true,
              fadeDelay: "-20% 0px",
              type: "stacked",
              images: [
                {
                  src: "/projects/obsurvus/obsurvus-iphone-1.png",
                  alt: "Obsurvus Responsive Website",
                  width: 35
                },
                {
                  src: "/projects/obsurvus/obsurvus-iphone-2.png",
                  alt: "Obsurvus Responsive Website",
                  width: 35
                }
              ]
            },
            {
              sequence: 7,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/obsurvus/obsurvus-pic1.jpg",
                  alt: "Obsurvus Photography",
                  width: 60
                },
                {
                  src: "/projects/obsurvus/obsurvus-pic2.jpg",
                  alt: "Obsurvus Photography",
                  width: 40,
                  caption: "The Obsurvus brand travels extremely well and is easily recognisable."
                }
              ]
            },
            {
              sequence: 8,
              fadeIn: true,
              type: "split",
              text:
                "<h2>What we've done...</h2><ul><li>Branding & logo creation</li><li>Web design & development</li><li>Content management system</li><li>Email marketing</li><li>Digital marketing</li><li>Photography</li><li>Design and print</li><li>SEO</li><li>Creative Strategy & Insight</li><li>Content writing</li><li>Social media</li><li>Web hosting and management</li></ul>",
              images: [
                {
                  src: "/projects/obsurvus/obsurvus-banner.jpg",
                  alt: "Obsurvus Banner Print",
                  width: 100
                }
              ]
            },
            {
              sequence: 9,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/obsurvus/obsurvus-brochure.jpg",
                  alt: "Obsurvus Brochure Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 10,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/obsurvus/obsurvus-stationery.jpg",
                  alt: "Obsurvus Stationary Design",
                  width: 100
                }
              ]
            }
          ]
        },
        {
          name: "Circuit Days>Track Days & Road Tours",
          intro: `Chameleon were invited into the engine room of Circuit Days back in 2006, today we're still firing on all cylinders and re-fuelling along the way.`,
          rows: [
            {
              sequence: 1,
              fadeIn: true,
              fadeDelay: "-20% 0px",
              background: "#000",
              edge: "bottom",
              type: "stacked",
              images: [
                {
                  src: "/projects/cd/CD-iphone-1.png",
                  alt: "Circuit Days Website Design",
                  width: 35
                },
                {
                  src: "/projects/cd/CD-iphone-2.png",
                  alt: "Circuit Days Website Design",
                  width: 35
                }
              ]
            },
            {
              sequence: 2,
              fadeIn: true,
              type: "text",
              align: "right",
              text: `<h2>How it began</h2><p>The Circuit Days team had originally decided that a company re-vamp was needed. The track day and road tour organiser had already established themselves as the UK's leading provider and they were keen to get in the fast lane to the European market.</p>`
            },
            {
              sequence: 3,
              fadeIn: true,
              type: "slideshow",
              images: [
                {
                  src: "/projects/cd/spa.jpg",
                  alt: "Circuit Days web design",
                  width: 100
                },
                {
                  src: "/projects/cd/aa.jpg",
                  alt: "Circuit Days web design",
                  width: 100
                },
                {
                  src: "/projects/cd/aa2.jpg",
                  alt: "Circuit Days web design",
                  width: 100
                },
                {
                  src: "/projects/cd/cr1.jpg",
                  alt: "Circuit Days web design",
                  width: 100
                },
                {
                  src: "/projects/cd/cr2.jpg",
                  alt: "Circuit Days web design",
                  width: 100
                }
              ]
            },
            {
              sequence: 4,
              fadeIn: true,
              type: "text",
              text: `<h2>Fifteen years on</h2><p>After endless digital media advances, graphic design, printing and a range of marketing tools, Circuit Days competitors continually struggle to keep up.</p><p>What makes this working relationship so unique is that it constantly, unremittingly evolves.</p>`
            },
            {
              sequence: 5,
              fadeIn: true,
              type: "slideshow",
              images: [
                {
                  src: "/projects/cd/mobi-1.jpg",
                  alt: "Circuit Days web design",
                  width: 100
                },
                {
                  src: "/projects/cd/mobi-2b.jpg",
                  alt: "Circuit Days web design",
                  width: 100
                }
              ]
            },
            {
              sequence: 6,
              fadeIn: false,
              type: "split",
              text: `<h2>What we've done...</h2><ul><li>Branding & logo creation </li><li>Web design & development </li><li>Custom built CMS & booking system </li><li>Email marketing </li><li>Digital marketing </li><li>Design and print </li><li>Promotional marketing </li><li>Google re-marketing ads </li><li>Marketing & strategy</li><li>Web hosting and management</li></ul>`,
              images: [
                {
                  src: "/projects/cd/cd-logo.jpg",
                  alt: "Circuit Days Logo Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 7,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/cd/CD-a5-flyer.jpg",
                  alt: "Circuit Days flyer design",
                  width: 52.5
                },
                {
                  src: "/projects/cd/CD-brochure.jpg",
                  alt: "Circuit Days brochure design",
                  width: 47.5
                }
              ]
            },
            {
              sequence: 8,
              fadeIn: false,
              type: "image",
              background: "#E82432",
              fadeDelay: "-20% 0px",
              class: "skewed",
              images: [
                {
                  src: "/projects/cd/cd-magazine.png",
                  alt: "Circuit Days Advert Design",
                  width: 100,
                  anim: "skew"
                }
              ]
            },
            {
              sequence: 9,
              fadeIn: true,
              type: "text",
              align: "right",
              text: `<p>With a strong dedicated database of clients, marketing campaigns are always a success for Circuit Days. And with the custom built booking management system that we've developed, administration is swift and effective.</p><p>We remain in the pit stop for Circuit Days, on constant standby with new ideas, adaptations and approaches to ensure that this client is always in pole position, both in the UK and Europe.</p>`
            },
            {
              sequence: 10,
              fadeIn: true,
              type: "image",
              class: "extramargin",
              images: [
                {
                  src: "/projects/cd/ipad-iphone3.jpg",
                  alt: "Circuit Days Website Content Management System",
                  width: 100
                }
              ]
            },
            {
              sequence: 11,
              fadeIn: false,
              type: "image",
              background: "#00AEEF",
              backgroundFull: true,
              padding: "10rem 0",
              color: "#ffffff",
              images: [
                {
                  src: "/projects/cd/mailerlite-stats.jpg",
                  alt: "Circuit Days E-marketing",
                  caption: `Chris Hoey, Founder of Circuit Days isn't someone to take a back seat. He knows that to stay on top of his game, the company has to constantly transform, re-devise and reinvigorate. This is only possible because of the mutual trust, respect and alliance which exists between the co-drivers of this most excellent partnership, Chameleon and Circuit Days.`,
                  width: 100,
                  captionClass: "limit"
                }
              ]
            },
            {
              sequence: 12,
              fadeIn: true,
              type: "split",
              class: "switch",
              text: `<p>"We have been working with Chameleon for over 15 years. For us it's important to work with a company that understands our need to be constantly innovating"</p><p><strong>Chris Hoey - Circuit Days Founder</strong></p>`,
              images: [
                {
                  src: "/projects/cd/cd-pic-2.jpg",
                  alt: "Circuit Days branding design",
                  width: 100
                }
              ]
            },
            {
              sequence: 13,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/cd/cd-pic-3.jpg",
                  alt: "Circuit Days branding design",
                  width: 52.5
                },
                {
                  src: "/projects/cd/cd-pic-1.jpg",
                  alt: "Circuit Days branding design",
                  width: 47.5
                }
              ]
            }
          ]
        },
        {
          name: `Funeral Directors`,
          intro: `We provide an honest and transparent service for all of our Funeral Director Clients.`,
          rows: [
            {
              sequence: 1,
              fadeIn: false,
              background: "#D3D2D2",
              padding: "0 0",
              type: "image",
              images: [
                {
                  src: "/projects/fd/desktop-sharpened.jpg",
                  alt: "Funeral director web design",
                  width: 100
                }
              ]
            },
            {
              sequence: 2,
              fadeIn: true,
              type: "text",
              align: "right",
              text: `<h2>How it began</h2><p>We commenced working with Funeral Directors back in 2014 after initially being contacted by C T Butterfield & Sons, as they were known back then.</p><p>Butterfields, as their brand became were approaching 140 years in business and they had decided this milestone should be celebrated with a complete new brand and identity, how little did they know it would have such a positive effect on their business.</p>`
            },
            {
              sequence: 3,
              fadeIn: true,
              background: "#D3D2D2",
              type: "image",
              class: "limitbg",
              images: [
                {
                  src: "/projects/fd/fd-image1.jpg",
                  alt: "Design and print for funeral directors",
                  width: 100
                }
              ]
            },
            {
              sequence: 4,
              fadeIn: true,
              background: "#D3D2D2",
              type: "image",
              class: "limitbg",
              images: [
                {
                  src: "/projects/fd/fd-image2.jpg",
                  alt: "Design and print for funeral directors",
                  width: 100
                }
              ]
            },
            {
              sequence: 5,
              fadeIn: true,
              type: "split",
              text: `<p>Jumping forward 8 years, we have established strong working relationships with a number of Funeral Directors throughout the U.K.</p><p>We continue to develop our services for and with our Funeral Director clients, helping them to market their business significantly better than the local competition.</p><h2>What we've done...</h2><ul><li>Branding & logo creation</li><li>Web design & development</li><li>Design and print</li><li>Photography</li><li>Advertising</li><li>SEO (search engine optimisation)</li><li>Marketing & strategy</li><li>Web hosting and management</li></ul>`,
              images: [
                {
                  src: "/projects/fd/fd-iphone-image.jpg",
                  alt: "Design and print for funeral directors",
                  width: 100
                }
              ]
            },

            {
              sequence: 6,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/fd/fd-image3.jpg",
                  alt: "Funenral director brochure design",
                  width: 100
                }
              ]
            },

            {
              sequence: 7,
              fadeIn: true,
              type: "text",
              align: "right",
              text: `<p>We are often praised for our integrity and our ability to ‘keep things simple’ and we’ve received many referrals as a result of this.</p><p>Working with FD’s is extremely rewarding business and we respect the relationships we have built.</p><p>Most Funeral Directors have a budget they wish to spend on marketing but you do not require an endless pot of money to develop amazing results and growth.</p>`
            },
            {
              sequence: 8,
              fadeIn: true,
              background: "#000",
              backgroundFull: true,
              type: "slideshow",
              images: [
                {
                  src: "/projects/fd/fd-pres1.jpg",
                  alt: "Funeral directors web design",
                  width: 100
                },
                {
                  src: "/projects/fd/fd-pres2.jpg",
                  alt: "Funeral directors  website design",
                  width: 100
                },
                {
                  src: "/projects/fd/fd-pres3.jpg",
                  alt: "Funeral directors  web development",
                  width: 100
                }
              ]
            }
          ]
        },
        {
          name: "Lincoln Welding Supplies",
          intro: `When a successful business reaches 25 years old, how do you plan the <strong><i>next</i></strong> quarter of a century? You take it online with a new e-commerce website.`,
          rows: [
            {
              sequence: 1,
              fadeIn: false,
              background: "#14225F",
              type: "image",
              class: "adjust",
              images: [
                {
                  src: "/projects/lw/lincoln-home-desktop.jpg",
                  alt: "Lincoln welding e-commerce website",
                  width: 100
                }
              ]
            },
            {
              sequence: 2,
              fadeIn: true,
              type: "split",
              class: "adjust",
              text: `<h2>What we've done...</h2><ul><li>Web design & development</li><li>E-commerce </li><li>Custom Built CMS </li><li>SEO  </li><li>Web hosting and management </li></ul>`,
              text2: `<h2>How it began</h2><p>Expanding a business to include online e-commerce can be a very daunting process, but the new management team at
Lincoln Welding knew it would be the right decision,
if they could ﬁnd a trusted partner to work with.</p>`
            },
            {
              sequence: 3,
              fadeIn: false,
              padding: "0",
              align: "right",
              videoID: "410958489",
              type: "video"
            },
            {
              sequence: 4,
              fadeIn: true,
              type: "text",
              text: `<p>Selling welding products online is nothing new, thousands do it, but Lincoln Welding wanted a platform and back-office system that was a custom fit to them. It has to be easy and effective to use, so they could manage the ongoing product population in-house after the initial product range was launched.</p><p>Recognising the need for a simple, custom fit, we initially carried out extensive research and assessed a number of e-commerce websites selling industrial products to similar target audiences as Lincoln Welding. This enabled us to present a variety of flexible, functional solutions for consideration, ranging in different price structures.</p>`
            },
            {
              sequence: 5,
              fadeIn: false,
              type: "image",
              images: [
                {
                  src: "/projects/lw/cms.jpg",
                  alt: "Bespoke CMS",
                  width: 100
                }
              ]
            },
            {
              sequence: 6,
              fadeIn: false,
              type: "image",
              images: [
                {
                  src: "/projects/lw/lincoln-cms2.jpg",
                  alt: "Lincoln Welding website CMS",
                  width: 100
                }
              ]
            },
            {
              sequence: 7,
              fadeIn: false,
              type: "image",
              images: [
                {
                  src: "/projects/lw/welding-product.jpg",
                  alt: "Lincoln Welding e-commerce web design",
                  width: 100
                }
              ]
            },
            {
              sequence: 8,
              fadeIn: true,
              align: "right",
              type: "text",
              text: `<p>By getting the fundamentals right at the start, we were able to develop the perfect custom website and CMS. Lincoln Welding selected from the list of suggested functionalities which we had offered in priority order. This enabled them to tailor their budget to achieve the perfect package with advance knowledge of potential future development options and costs as and when they are required.</p>`
            }
          ]
        },
        {
          name: "South Yorkshire Police Federation",
          intro: `When your members demand up to date, uninterrupted support, your website and mobile app has to be both durable and future proof.`,
          rows: [
            {
              sequence: 1,
              fadeIn: true,
              fadeDelay: "-20% 0px",
              background: "#0DA14A",
              type: "stacked",
              images: [
                {
                  src: "/projects/sypf/sypf-iphone1.png",
                  alt: "South Yorkshire Police Federation App Build",
                  width: 40
                },
                {
                  src: "/projects/sypf/sypf-iphone2.png",
                  alt: "South Yorkshire Police Federation App Build",
                  width: 40
                }
              ]
            },
            {
              sequence: 2,
              fadeIn: true,
              type: "text",
              align: "right",
              class: "adjust",
              text: `<h2>How it began</h2><p>Chameleon originally started working with SYPF back in 2012. During the intervening years, both our relationship and services have developed as we have extended our affiliation into a number of Police Federations throughout England and Scotland.</p>`
            },
            {
              sequence: 3,
              fadeIn: true,
              type: "split",
              text:
                "<h2>What we've done...</h2><ul><li>Wed design and development</li><li>Mobile app development</li><li>Custom built CMS</li><li>Content integration / population</li><li>Creative strategy & insight</li><li>Hosting and management</li></ul><p><br />The development of the new website and mobile app has propelled SYPF to another level.  Their members, Constables, Inspectors, Chief Inspectors and Police staff all demand prompt, accurate information and the specially designed mobile app means that this is now readily available.</p>",

              videoID: "420705286"
            },
            {
              sequence: 4,
              fadeIn: false,
              background: "#203064",
              type: "image",
              images: [
                {
                  src: "/projects/sypf/sypf-desktop-home.jpg",
                  alt: "South Yorkshire Police Federation Web Development",
                  width: 100
                }
              ]
            },
            {
              sequence: 5,
              fadeIn: true,
              type: "text",
              align: "right",
              class: "adjust",
              text: `<h2>Operation SYPF update</h2><p>The user friendly website offers an easy to navigate information hub which is also accessible to the general public.
The web app enables members and designated staff to download the site to their phone and use it both on and offline.</p><p>All information is managed via the Chameleon custom built Content Management System which means that SYPF can easily update all content ensuring it can be trusted to stay relevant and accurate.</p><p>This "tour de force" has been made possible by the integrated in house services of Chameleon. As a team, we all work together, to think, talk, listen and action; offering overall solutions that work in a cohesive, compatible system.</p>`
            },
            {
              sequence: 6,
              fadeIn: true,
              type: "text",
              text: `<h1 class='controlled'>Easily controlled by Chameleon's Content Management System</h1>`
            },
            {
              sequence: 7,
              fadeIn: true,
              background: "#918F8F",
              inset: true,
              type: "slideshow",
              images: [
                {
                  src: "/projects/sypf/cms1.jpg",
                  alt: "South Yorkshire Police Federation CMS",
                  width: 100
                },
                {
                  src: "/projects/sypf/cms2.jpg",
                  alt: "South Yorkshire Police Federation CMS",
                  width: 100
                },
                {
                  src: "/projects/sypf/cms3.jpg",
                  alt: "South Yorkshire Police Federation CMS",
                  width: 100
                },
                {
                  src: "/projects/sypf/cms4.jpg",
                  alt: "South Yorkshire Police Federation CMS",
                  width: 100
                }
              ]
            },
            {
              sequence: 8,
              fadeIn: true,
              type: "text",
              text: `<h1 class='connected'>Keeping Cops connected</h1>`
            },
            {
              sequence: 9,
              fadeIn: false,
              background: "#203064",
              type: "image",
              images: [
                {
                  src: "/projects/sypf/sypf-news.jpg",
                  alt: "South Yorkshire Police Federation Web Development",
                  width: 100
                }
              ]
            },
            {
              sequence: 10,
              fadeIn: true,
              type: "split",
              text:
                "<h2>Chameleon really do listen and deliver</h2><p>When I took over as Chair of the South Yorkshire Police Federation in 2017, our existing website was nice and polished, but I wasn't sure if it was fit for purpose.</p><p>After canvassing our members, it became clear that they required a more user friendly and responsive system. After discussing this with Craig at Chameleon, he and his team had planned a much more effective website for our members, which was also designed and built around an app. This major step forward meant that our members had instant access to all the information they needed whilst out on patrol.</p><p>Chameleon have an innovative way of working where they take a brief, find out what you want to achieve, and then present you with the perfect solution.</p>",
              images: [
                {
                  src: "/projects/sypf/chair.jpg",
                  alt: "SYPF Chair",
                  width: 100
                }
              ]
            },
            {
              sequence: 11,
              fadeIn: false,
              type: "image",
              images: [
                {
                  src: "/projects/sypf/sypf-multi-iphones.jpg",
                  alt: "South Yorkshire Police Federation Web Development",
                  width: 100
                }
              ]
            }
          ]
        },
        {
          name: "Allsports Nutrition",
          intro: `A strong, slick new brand fit to endure growth.`,
          rows: [
            {
              sequence: 1,
              fadeIn: false,
              type: "image",
              images: [
                {
                  src: "/projects/as/A-logo.jpg",
                  alt: "Allsports Nutrition Branding Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 2,
              fadeIn: true,
              align: "right",
              type: "text",
              text: `<h2>How it began</h2><p>The Allsports Nutrition brand was nothing new, in fact it had been around for over 40 years. The main problem was that it lacked strength in the market place and a differing range of product packaging made it confusing for the customer.</p>`
            },
            {
              sequence: 4,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/as/allsports-logo.jpg",
                  alt: "Allsports Nutrition Branding Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 5,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/as/Packaging-Mockup-all4.jpg",
                  alt: "Allsports Nutrition Package Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 6,
              fadeIn: true,
              align: "left",
              type: "text",
              text: `<h2>Our solution</h2><p>We worked very closely with the team at Allsports re-defining their business image.</p><p>Several key elements were required, in order to meet the clients requirements and create a stand-out brand that could be utilised across a range of different printing processes, often associated with packaging production.</p><p>The A star icon was designed to form a strong anchor for all sub brands and we explored a range of typography and colours to bring the entire brand together and offer the right image for their male and female customer base as well as different athlete types.</p>`
            },
            {
              sequence: 7,
              fadeIn: true,
              type: "image",
              background: "#E9E9E9",
              backgroundFull: true,
              class: "nomargin",
              images: [
                {
                  src: "/projects/as/all-logos.png",
                  alt: "Allsports Nutrition Branding Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 8,
              fadeIn: true,
              type: "split",
              text: `<h2>What we've done...</h2><ul><li>Brand strategy</li><li>Branding & logo creation</li><li>Packaging design</li><li>Promotional marketing</li><li>Vehicle graphics</li></ul>`,
              images: [
                {
                  src: "/projects/as/B-Card.jpg",
                  alt: "Allsports Nutrition Business Cards Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 9,
              fadeIn: true,
              type: "image",
              images: [
                {
                  src: "/projects/as/Car-Mockup.jpg",
                  alt: "Allsports Nutrition Branding Design",
                  width: 100
                }
              ]
            },
            {
              sequence: 10,
              fadeIn: true,
              type: "split",
              class: "switch",
              text: `<p>"We had a fantastic experience working with Chameleon throughout the process of re-branding and redefining our business"</p><p><strong>Allsports Nutrition</strong></p>`,
              images: [
                {
                  src: "/projects/as/Snapback-Cap.jpg",
                  alt: "Allsports Nutrition Branding Design",
                  width: 100
                }
              ]
            }
          ]
        }
      ]
    }
  },

  created: function() {
    this.project = this.projects[this.$route.name.split("-")[1]]
  }
}
</script>

<style scoped>
.project {
  padding-top: 28rem;
  font-weight: 300;
}
.heading {
  font-family: "Arvo", serif;
  font-weight: 700;
  font-size: 7rem;
  max-width: 60%;
  margin-bottom: 3rem;
}
.intro {
  font-size: 2.5rem;
  line-height: 1.5;
  padding-bottom: 10vh;
  max-width: 80%;
}
.inner {
  z-index: 2;
}
img.bordered {
  border: 1px solid #f0f0f0;
}
.rows {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "1"
    "2"
    "3"
    "4"
    "5"
    "6"
    "7"
    "8"
    "9"
    "10"
    "11"
    "12"
    "13"
    "14"
    "15"
    "16"
    "17"
    "18"
    "19"
    "20";
}
.row {
  padding: 5rem 0;
  display: table;
  position: relative;
  width: 100%;
}
.row.extramargin {
  margin-bottom: 5rem;
}
.row img {
  z-index: 2;
  position: relative;
  float: left;
  transition: all 1s;
}
.row.fadein img {
  transform: scale(0.9);
  margin-top: 5rem;
}
.row.fadein.in-viewport img {
  margin-top: 0;
  transform: scale(1);
}
.background {
  background: #f79222;
  display: block;
  content: " ";
  position: absolute;
  top: 15rem;
  height: 75%;
  left: 0;
  width: 100%;
  z-index: 1;
}
.background.full {
  top: 0;
  height: 100%;
}
.background.bottom {
  height: calc(100% - 20rem);
}
.stacked {
  line-height: 0;
}
.stacked img {
  position: absolute;
  left: 50%;
  right: auto;
  top: 0;
  transform: translateX(-50%) scale(1) !important;
  float: none;
  margin-top: 0 !important;
  transition: all 1s ease-in-out;
}
.stacked img:first-child {
  position: relative;
}

img.skew {
  opacity: 0;
  transform: skew(15deg, 15deg);
}

.row.in-viewport img.skew {
  opacity: 1;
  transform: skew(0deg, 0deg);
}

.row.fadein.in-viewport .stacked-2 img {
  left: 55%;
  transform: translateX(0) scale(1) !important;
}
.row.fadein.in-viewport .stacked-2 img:first-child {
  left: 5%;
}

.row.fadein.in-viewport .stacked-3 img {
  transform: translateX(0) scale(1) !important;
}

.row.fadein.in-viewport .stacked-3 img:nth-child(1) {
  left: 0%;
}
.row.fadein.in-viewport .stacked-3 img:nth-child(2) {
  left: 37.5%;
}
.row.fadein.in-viewport .stacked-3 img:nth-child(3) {
  left: 75%;
}

.text {
  font-size: 2.5rem;
  font-size: 2.5rem;
  line-height: 1.5;
  max-width: 60%;
  padding: 4rem 0;
}
.right {
  text-align: right;
  float: right;
}
.center {
  text-align: center;
  margin: auto;
}
.caption {
  font-size: 2.5rem;
  position: relative;
  top: 2rem;
}
.caption.limit {
  max-width: 90rem;
  padding-top: 3rem;
  display: block;
  clear: both;
}
.view-more {
  text-align: center;
  font-size: 3rem;
  padding-top: 5rem;
}
.view-more a {
  border: 1px solid #f0f0f0;
  padding: 1rem 2rem;
  font-family: "Arvo", serif;
  font-weight: 700;
  transition: all 0.3s;
  background: linear-gradient(to right, #fff 50%, #f5f5f5 50%);
  background-size: 200% 100%;
}
.split-text {
  float: left;
  width: 47.5%;
  margin-right: 5%;
  font-size: 2.5rem;
}
.switch .split-text {
  float: right;
  margin-right: 0;
}
.switch .split-image {
  float: left;
  margin-right: 5%;
}
.split-text-right {
  float: right;
  text-align: right;
  margin-right: 0;
  padding-bottom: 10rem;
}
.split-text >>> h2 {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.split-text >>> p,
.text >>> p {
  margin-bottom: 2rem;
  line-height: 1.5;
}
.split-image {
  float: left;
  max-width: 47.5%;
}
.inner >>> ul {
  list-style-type: none;
}
.inner >>> ul li {
  margin: 1rem 0;
}

.inner >>> .wb-btn {
  padding: 0.5rem 1.5rem;
  padding-right: 3rem;
  background: #434f50;
  border: 0;
  border-bottom: 5px solid #f79227;
  color: #fff;
  font-size: 2rem;
  margin-top: 6rem;
  margin-right: 3rem;
}
.inner >>> .wb-btn img {
  vertical-align: middle;
  margin-right: 1rem;
}
.scroller {
  position: relative;
  overflow: hidden;
  line-height: 0;
  max-width: 566px;
  width: 90%;
  float: right;
  margin-right: 5%;
  margin-top: -20rem;
  margin-bottom: -5rem;
}
.row img.scroller-frame {
  z-index: 2;
  float: none;
  left: 0;
  top: 0;
  position: relative;
}
.row img.scroller-content {
  position: absolute;
  float: none;
  left: 0;
  top: 0;
  z-index: 1;
}

.video-right {
  max-width: 70%;
  position: relative;
  left: 30%;
}
.video-left {
  max-width: 70%;
}
.video-half {
  float: left;
  width: 47.5%;
  padding-top: 3rem;
}
.video-half .embed-container {
  padding-bottom: 100%;
  clip-path: inset(1% 0 1% 0);
}
.text >>> h1 {
  font-size: 5rem;
  line-height: 1.2;
}
.text >>> .controlled {
  position: relative;
  margin-top: -5rem;
  bottom: -5rem;
}
.text >>> .connected {
  position: relative;
  margin-top: -5rem;
  bottom: -5rem;
}

@media screen and (max-width: 1050px) {
  .heading,
  .intro {
    max-width: 100%;
  }
  .project {
    padding-top: 20rem;
  }
}
@media screen and (max-width: 750px) {
  .text {
    max-width: 100%;
  }
  .project {
    padding-top: 15rem;
  }
  .heading {
    font-size: 4rem;
  }
  .intro,
  .text,
  .split-text,
  .caption {
    font-size: 2rem;
  }
  .text {
    padding: 0;
  }
  .row {
    padding: 2.5rem 0;
  }
  .row.row.extramargin {
    margin-bottom: 2.5rem;
  }

  .background.bottom {
    height: calc(100% - 12.5rem);
  }
  .image img {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 3rem;
  }
  .nomargin .image img {
    margin-bottom: 0;
  }
  .row.adjust {
    margin-bottom: -5rem;
  }
  .split-text,
  .split-image {
    max-width: none;
    width: 100%;
    float: none;
    margin-right: 0;
  }
  .split-text {
    margin-bottom: 5rem;
  }
  .split-text-right {
    margin-bottom: -5rem;
  }
  .background {
    border-bottom: 5rem solid white;
  }
  .background.full {
    border-bottom: 0;
  }
  .row.skewed .background {
    top: 5rem;
  }
  .row.limitbg .background {
    height: 65%;
  }
  .inner >>> .nomobile {
    display: none !important;
  }

  .view-more {
    padding-top: 8rem;
  }
  .video-right {
    max-width: 100%;
    left: 0%;
  }
  .video-left {
    max-width: 100%;
  }
  .video {
    margin-bottom: 5rem;
  }
  .video-half {
    width: 100%;
    padding-top: 0;
  }
  .text >>> h1 {
    font-size: 5rem;
  }
  .text >>> .connected {
    margin-top: -3rem;
    bottom: -3rem;
  }
  .text >>> .controlled {
    margin-top: 2rem;
    bottom: 0;
  }
}
@media screen and (max-width: 450px) {
  .background {
    border-bottom: 6.5rem solid white;
  }
  .background.bottom {
    top: 10rem;
    height: calc(100% - 7.5rem);
    border-bottom: 5rem solid white;
  }
  .text >>> h1 {
    font-size: 3rem;
  }
  .text >>> h2 {
    line-height: 1.2;
    padding: 2rem 0;
  }
}
</style>
