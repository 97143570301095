<template>
  <section v-in-viewport.once="{ margin: '-50% 0px' }">
    <div class="hero">
      <div class="inner">
        <img src="../assets/web-design-agency-doncaster.png" alt="Design agency" />
      </div>
    </div>
    <div>
      <div class="inner">
        <router-link class="link" to="/work/circuit-days">
          <span class="link-main">
            Web design, branding
            <br />design &amp; print
          </span>
          <span class="link-sub">
            <span>Powering Circuit Days - The UK’s leading track day provider</span>
          </span>
          <span class="link-more">
            <span>read more</span>
          </span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style scoped>
section {
}
.hero {
  text-align: center;
  width: 90%;
  margin: auto;
  position: relative;
}
.hero img {
  width: 800px;
}
.hero::before {
  background: #0864a2;
  background-image: url(/images/cd-logos-background.jpg);
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 50% auto;
  display: block;
  content: " ";
  position: absolute;
  top: 15%;
  height: 74.5%;
  width: 0%;
  transition: all 1s ease-in-out;
  transform: scale(1.5) skew(15deg, 15deg);
}
.in-viewport .hero::before {
  width: 100%;
  transform: scale(1) skew(0deg, 0deg);
}

.link {
  margin: 6rem 0;
  display: block;
  color: black;
}
.link > span {
  display: block;
}
.link-main {
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 1rem;
  font-family: "Arvo", serif;
  font-weight: 700;
}
.link-sub {
  font-size: 3.5rem;
  margin-bottom: 2rem;
}
.link-more > span {
  font-size: 3rem;
  background: #e7e8e9;
  padding: 1rem 2rem;
  display: inline-block;
  font-family: "Arvo", serif;
  font-weight: 700;
}

@media screen and (min-width: 1201px) {
  .link {
    padding-left: 52%;
    margin-top: 0;
  }
}
@media screen and (max-width: 650px) {
  .link-main {
    font-size: 4rem;
  }
  .link-sub {
    font-size: 3rem;
  }
  .link-more > span {
    font-size: 2rem;
  }
}
</style>
